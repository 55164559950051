import PropTypes from "prop-types";
import React from "react";

const filter = ({ name, items, selectedItem, callbackOnChange, canClose }) => {
	return (
		<>
			{(selectedItem === "0" || !canClose) && (
				<select
					name={name}
					id={name}
					className="filter"
					onChange={e => callbackOnChange(e.target.value)}
					onBlur={e => callbackOnChange(e.target.value)}
					value={selectedItem}
				>
					{items.map(item => {
						return (
							<option value={item.key} key={item.key}>
								{item.value}
							</option>
						);
					})}
				</select>
			)}
			{selectedItem !== "0" && canClose && items && (
				<button
					className="filter-selected"
					onClick={e => callbackOnChange("0")}
				>
					<span>{items.filter(i => i.key === selectedItem)[0].value}</span>
				</button>
			)}
		</>
	);
};

filter.propTypes = {
	name: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	callbackOnChange: PropTypes.func.isRequired,
	selectedItem: PropTypes.string,
};

export default filter;
