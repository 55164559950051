import PropTypes from "prop-types";
import React from "react";

const ResourceCard = ({ callback, resource, category, label }) => {
	return (
		<div className="col col-6 col-md-4 resource-card-wrapper" data-id={resource.id}>
			<div className="resource-card">
				<div className="resource-card-padding">
					<div className="resource-card-type">
						{resource.media_medical__c ||
							resource.congress__c ||
							resource.publisher__c}
					</div>
					<div className="resource-card-year">{resource.year__c}</div>
					<div
						className={
							(category === "Congress Activity" &&
								"resource-card-heading small") ||
							((category === "Professional Resources" ||
								category === "Publication") &&
								"resource-card-heading subhead") ||
							"resource-card-heading"
						}
					>
						{resource.publication_type__c || resource.title__v}
					</div>
					<div className="resource-card-copy">
						{(category === "Congress Activity" && resource.title__v) ||
							resource.medical_description__c}
					</div>
					<button
						data-identifier={
							resource.document_number__v + "_" + resource.filename__v
						}
						className="btn btn-primary"
						onClick={e => callback()}
					>
						{label
							? label
							: (resource.media_medical__c &&
								resource.media_medical__c[0] === "Video") || resource.id === 5690
								? "Watch Video"
								: resource.media_medical__c &&
									resource.media_medical__c[0] === "Game"
									? "Play Game"
									: resource.external_url__c
										? "View"
										: "Download"}
					</button>
				</div>
			</div>
		</div>
	);
};

ResourceCard.propTypes = {
	callback: PropTypes.func.isRequired,
	resource: PropTypes.object.isRequired,
	category: PropTypes.string,
	label: PropTypes.string,
};

export default ResourceCard;
