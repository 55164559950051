export function formatEventDate(cEvent) {
  if (!cEvent.event_end_date__c || !cEvent.event_start_date__c) {
    return "";
  }

  var start = new Date(cEvent.event_start_date__c);
  var end = new Date(cEvent.event_end_date__c);

  var dateFormat = start.toLocaleString(undefined, {
    timeZone: "UTC",
    month: "short",
    day: "numeric",
  });

  if (start.getMonth() === end.getMonth() && start.getDay() === end.getDay()) {
    dateFormat +=
      ", " +
      start.toLocaleString(undefined, { timeZone: "UTC", year: "numeric" });
  } else if (start.getMonth() !== end.getMonth()) {
    dateFormat +=
      " - " +
      end.toLocaleString(undefined, {
        timeZone: "UTC",
        month: "short",
        day: "numeric",
      });
  } else {
    dateFormat +=
      " - " +
      end.toLocaleString(undefined, { timeZone: "UTC", day: "numeric" });
    dateFormat +=
      ", " +
      start.toLocaleString(undefined, { timeZone: "UTC", year: "numeric" });
  }

  return dateFormat;
}

export function sortFilterAlpha(items, DSC) {
  items.sort(function (a, b) {
    var nameA = a.value.toUpperCase(); // ignore upper and lowercase
    var nameB = b.value.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  return items;
}

export function sortFilterNumeric(items) {
  items.sort(function (a, b) {
    return a - b;
  });
  return items;
}

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
