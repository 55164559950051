import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import CloseIcon from "../images/icon-close.inline.svg";
import VideoPlayer from "./videoPlayer";

import video_burden from "../videos/Akebia_MOD_Animation_01_Burden_of_Anemia_due_to_CKD.mp4";
import poster_burden from "../images/posters/mod_poster.gif";
import thumb_burden from "../images/thumb_burden.jpg";

import video_patho from "../videos/Akebia_MOD_Animation_02_Pathogenesis_of_Anemia_due_to_CKD.mp4";
import poster_patho from "../images/posters/poster_mod_patho.jpg";
import thumb_patho from "../images/thumb_patho.jpg";

import video_current from "../videos/Akebia_MOD_Animation_03_Progression_of_Anemia_due_to_CKD.mp4";
import poster_current from "../images/posters/poster_mod_current.jpg";
import thumb_current from "../images/thumb_current.jpg";

import video_improve from "../videos/Akebia_MOD_Animation_04_Current_Therapeutic_Landscape_due_to_CKD.mp4";
import poster_improve from "../images/posters/poster_mod_improve.jpg";
import thumb_improve from "../images/thumb_improve.jpg";

Modal.setAppElement("#___gatsby");

const playlist = [
	{
		video: video_burden,
		poster: poster_burden,
		thumbnail: thumb_burden,
		title: "Burden of Anemia Due to CKD",
	},
	{
		video: video_patho,
		poster: poster_patho,
		thumbnail: thumb_patho,
		title: "Pathogenesis of Anemia Due to CKD",
	},
	{
		video: video_current,
		poster: poster_current,
		thumbnail: thumb_current,
		title: "Progression of Anemia Due to CKD",
	},
	{
		video: video_improve,
		poster: poster_improve,
		thumbnail: thumb_improve,
		title: "Therapeutic Landscape and Recent Scientific Advancement",
	},
];

const ModalExemptSeries = props => {
	return (
		<Modal
			className="modal modal-videos"
			shouldCloseOnEsc={true}
			shouldCloseOnOverlayClick={true}
			{...props}
		>
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-4">
						<h1>
							Anemia Due to CKD Video Series featuring key topics related to
							burden of disease, disease pathogenesis, CKD progression, and
							therapeutic landscape
						</h1>
						<p>
							This 4-part video series sheds light on key aspects of anemia due
							to CKD. Together we will:
						</p>
						<ul className="long-list">
							<li>
								Learn about the clinical burden of the disease and how anemia
								can contribute to worsening of outcomes in CKD patients
							</li>
							<li>
								Discover the complex and multifactorial events leading to anemia
								due to CKD
							</li>
							<li>
								Visit the growing evidence that associates anemia with CKD
								progression
							</li>
							<li>
								Explore therapeutic approaches and recent scientific advancement
								to address anemia
							</li>
						</ul>
					</div>
					<div className="col">
						<VideoPlayer playlist={playlist} identifier="MED-DS-US-0021" />
					</div>
				</div>
				<button className="close-button" onClick={props.onRequestClose}>
					<CloseIcon />
				</button>
			</div>
		</Modal>
	);
};

ModalExemptSeries.propTypes = {
	isOpen: PropTypes.bool.isRequired,
};

export default ModalExemptSeries;
