import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import CloseIcon from "../images/icon-close.inline.svg";

Modal.setAppElement("#___gatsby");

const ModalGame = props => {
	return (
		<Modal
			className="modal modal-iframe"
			shouldCloseOnEsc={true}
			shouldCloseOnOverlayClick={true}
			{...props}
		>
			<div className="iframe-border">
				<div className="iframe-wrap">
					<iframe
						src="/game/index.html"
						frameBorder="0"
						title="akebia-unity-moa-game"
					></iframe>
				</div>
				<button className="close-button" onClick={props.onRequestClose}>
					<CloseIcon />
				</button>
			</div>
		</Modal>
	);
};

ModalGame.propTypes = {
	isOpen: PropTypes.bool.isRequired,
};

export default ModalGame;
