/**
 * This component is mostly documented in the readme, go read that ;)
 */

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import PlayButton from "../images/bttn_play_video.png";
import PlayAllButton from "../images/bttn_play_all.png";

const VideoPlayer = props => {
	const { playlist, displayChapters, startingIndex, identifier } = props;
	const max = playlist.length - 1;

	const ref = useRef(null);

	const [playlistIndex, setPlaylistIndex] = useState(startingIndex || 0);
	const [currentVideo, setCurrentVideo] = useState({
		...playlist[playlistIndex],
	});
	const [autoplaying, setAutoplaying] = useState(
		playlist.length === 1 ? true : false
	);
	const [buttonsVisible, setButtonsVisible] = useState(true);

	const handleVideoEnd = () => {
		if (autoplaying) {
			let newIndex = playlistIndex + 1;

			if (newIndex > max) {
				newIndex = 0;
			}

			setCurrentVideo({
				...playlist[newIndex],
			});
			setPlaylistIndex(newIndex);
		}
	};

	const displayThumbs = () => {
		if (displayChapters === false) {
			return false;
		} else {
			return playlist.length > 1 || displayChapters;
		}
	};

	const setVideo = newIndex => {
		setCurrentVideo({
			...playlist[newIndex],
		});
		setPlaylistIndex(newIndex);
		setAutoplaying(true);
	};

	const clickPlayButton = () => {
		setButtonsVisible(false);
		ref.current.play();
	};

	const clickPlayAllButton = () => {
		setButtonsVisible(false);
		setAutoplaying(true);
		ref.current.play();
	};

	return (
		<>
			<div
				className={
					currentVideo.isWide ? "video-wrapper ultra-wide" : "video-wrapper"
				}
			>
				{/* eslint-disable jsx-a11y/media-has-caption */}
				<video
					id="modal-video-player"
					src={currentVideo.video}
					controls={!buttonsVisible || playlist.length === 1}
					className="responsive-video-player"
					poster={currentVideo.poster}
					onEnded={handleVideoEnd}
					preload="metadata"
					autoPlay={autoplaying}
					width="100%"
					ref={ref}
					data-identifier={identifier}
				></video>
				{/* eslint-enable */}
				{buttonsVisible && playlist.length > 1 && (
					<div className="video-button-container">
						<button className="play-button" onClick={clickPlayButton}>
							<img src={PlayButton} alt="Play Video" />
						</button>
						<button className="play-button" onClick={clickPlayAllButton}>
							<img src={PlayAllButton} alt="Play All Videos" />
						</button>
					</div>
				)}
			</div>
			{displayThumbs() && (
				<div className="video-thumbnails">
					<div className="row">
						{playlist.map((playlistItem, index) => {
							return (
								<div className="col" key={`item-${index}`}>
									{/* todo: fix a11y issues here */}
									{/* eslint-disable */}
									<div
										className="thumbnail"
										style={{
											backgroundImage: `url(${playlistItem.thumbnail})`,
										}}
										onClick={() => setVideo(index)}
									>
										{/* eslint-enable */}
										<div className="thumbnail-inner">
											<p>{playlistItem.title}</p>
											{playlistIndex === index && (
												<p className="now-playing">Now Playing</p>
											)}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};

VideoPlayer.propTypes = {
	playlist: PropTypes.array.isRequired,
	displayChapters: PropTypes.bool,
	startingIndex: PropTypes.number,
};

export default VideoPlayer;
