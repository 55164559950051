import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import CloseIcon from "../images/icon-close.inline.svg";
import VideoPlayer from "../components/videoPlayer";

Modal.setAppElement("#___gatsby");

const ModalSingleVideo = props => {
	const { onRequestClose, playlist, identifier, ...others } = props;

	return (
		<Modal
			className="modal modal-videos"
			shouldCloseOnEsc={true}
			shouldCloseOnOverlayClick={true}
			{...others}
		>
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-4">
						<h1>{playlist[0].title}</h1>
						<p>{playlist[0].description}</p>
					</div>
					<div className="col">
						<VideoPlayer playlist={playlist} identifier={identifier} />
					</div>
				</div>
				<button className="close-button" onClick={onRequestClose}>
					<CloseIcon />
				</button>
			</div>
		</Modal>
	);
};

ModalSingleVideo.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	playlist: PropTypes.array.isRequired,
	onRequestClose: PropTypes.func.isRequired,
};

export default ModalSingleVideo;
