import React, { useEffect, useState, useRef } from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import SEO from "../components/seo";
import Hero from "../components/hero";
import backdrop from "../images/header-image-02.png";
import axios from "axios";
import ModalGame from "../components/modalGame";
import ModalSingleVideo from "../components/modalSingleVideo";
import ModalExemptSeries from "../components/modalExemptSeries";
import { useModal } from "../context/context";
import ResourceCard from "../components/resources/resourceCard";
import Filter from "../components/resources/filter";
import { sortFilterAlpha } from "../utils/index";

const categories = [
	{ key: "Disease Education", value: "Disease Education" },
	{ key: "Congress Activity", value: "Congress Activity" },
	{ key: "Publication", value: "Publications" },
	{ key: "Professional Resources", value: "Professional Resources" },
	{ key: "Medical Information", value: "Medical Information" },
];

const Resources = ({ location }) => {

	let scrollRef = useRef();

	const { showModal } = useModal();
	const [category, setCategory] = useState(categories[0].key);
	const [selectedTopic, setSelectedTopic] = useState("0");
	const [currentTopics, setCurrentTopics] = useState([]);
	const [selectedMedia, setSelectedMedia] = useState("0");
	const [currentMedia, setCurrentMedia] = useState([]);
	const [selectedYear, setSelectedYear] = useState("0");
	const [currentYears, setCurrentYears] = useState([]);
	const [selectedResourceType, setSelectedResourceType] = useState("0");
	const [currentResourceTypes, setCurrentResourceTypes] = useState([]);
	const [selectedOrganization, setSelectedOrganization] = useState("0");
	const [currentOrganization, setCurrentOrganization] = useState([]);
	const [selectedPublisher, setSelectedPublisher] = useState("0");
	const [currentPublishers, setCurrentPublishers] = useState([]);
	const [selectedCongress, setSelectedCongress] = useState("0");
	const [currentCongresses, setCurrentCongresses] = useState([]);
	const [selectedPublicationType, setSelectedPublicationType] = useState("0");
	const [currentPublicationTypes, setCurrentPublicationTypes] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [data, setData] = useState([]);
	const [openGameModal, setOpenGameModal] = useState(false);
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [videoPath, setVideoPath] = useState("");
	const [currentDocument, setCurrentDocument] = useState();
	const [currentDocuments, setCurrentDocuments] = useState([]);

	function toTitleCase(str) {
		return str.replace("#", "").replace("-", " ").replace(
			/\w\S*/g,
			function (txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			}
		);
	}

	useEffect(
		() => {
			function filterDocuments() {
				var filteredDocs = [];
				if (
					category === "Disease Education" &&
					(selectedMedia !== "0" || selectedTopic !== "0")
				) {
					filteredDocs = data.filter(
						doc =>
							(selectedMedia !== "0"
								? (doc.media_medical__c !== null ? doc.media_medical__c[0] === selectedMedia : false)
								: true) &&
							(selectedTopic !== "0"
								? doc.topic__c.includes(selectedTopic)
								: true)
					);
					//console.log(JSON.stringify(filteredDocs));
				} else if (
					category === "Congress Activity" &&
					(selectedYear !== "0" ||
						selectedCongress !== "0" ||
						selectedPublicationType !== "0" ||
						selectedTopic !== "0")
				) {
					filteredDocs = data.filter(
						doc =>
							(selectedYear !== "0" ? doc.year__c === selectedYear : true) &&
							(selectedCongress !== "0"
								? doc.congress__c === selectedCongress
								: true) &&
							(selectedPublicationType !== "0"
								? (doc.publication_type__c !== null ? doc.publication_type__c[0] === selectedPublicationType : false)
								: true) &&
							(selectedTopic !== "0" && selectedTopic !== null
								? (doc.topic__c !== null ? doc.topic__c.includes(selectedTopic) : false)
								: true)
					);
				} else if (
					category === "Publication" &&
					(selectedYear !== "0" ||
						selectedPublisher !== "0" ||
						selectedTopic !== "0")
				) {
					filteredDocs = data.filter(
						doc =>
							(selectedYear !== "0" ? doc.year__c === selectedYear : true) &&
							(selectedPublisher !== "0"
								? doc.publisher__c === selectedPublisher
								: true) &&
							(selectedTopic !== "0"
								? doc.topic__c.includes(selectedTopic)
								: true)
					);
				} else if (
					category === "Professional Resources" &&
					(selectedYear !== "0" ||
						selectedTopic !== "0" ||
						selectedResourceType !== "0" ||
						selectedOrganization !== "0")
				) {
					filteredDocs = data.filter(
						doc =>
							(selectedYear !== "0" ? doc.year__c === selectedYear : true) &&
							(selectedResourceType !== "0"
								? (doc.resource_type__c !== null ? doc.resource_type__c[0] === selectedResourceType : false)
								: true) &&
							(selectedTopic !== "0" && selectedTopic !== null
								? (doc.topic__c !== null ? doc.topic__c.includes(selectedTopic) : false)
								: true) &&
							(selectedOrganization !== "0" && selectedOrganization !== null
								? (doc.organization__c !== null ? doc.organization__c.includes(selectedOrganization) : false)
								: true)
					);
				} else if (
					category === "Medical Information" &&
					searchInput.trim().length > 2
				) {
					const terms = searchInput
						.split(" ")
						.filter(
							t => t !== "and" && t !== "the" && t !== "an" && t !== "or"
						);
					filteredDocs = data.filter(doc => {
						var include = true;
						terms.forEach(t => {
							if (
								(!doc.medical_tag__c ||
									!doc.medical_tag__c
										.toLowerCase()
										.includes(t.toLowerCase())) &&
								(!doc.title__v ||
									!doc.title__v.toLowerCase().includes(t.toLowerCase())) &&
								(!doc.medical_description__c ||
									doc.medical_description__c
										.toLowerCase()
										.includes(t.toLowerCase()))
							) {
								include = false;
							}
						});
						return include;
					});
				} else if (
					category === "Professional Resources" ||
					category === "Disease Education"
				) {
					filteredDocs = data;
				} else {
					filteredDocs = [];
				}
				setCurrentDocuments(filteredDocs);
				//console.log(JSON.stringify(filteredDocs));

				filteredDocs.forEach(doc => {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: "dropdownSelection",
						eventAction: "Result",
						eventLabel: doc.document_number__v,
					});
				});
				// console.log(window.dataLayer);
			}
			filterDocuments();

		},
		/* eslint-disable react-hooks/exhaustive-deps */
		[
			selectedTopic,
			selectedYear,
			selectedPublisher,
			selectedPublicationType,
			selectedCongress,
			selectedMedia,
			selectedResourceType,
			selectedOrganization,
			searchInput,
			data,
		]
		/* eslint-enable */
	);

	useEffect(
		() => {
			var hash = location.hash;
			hash = toTitleCase(hash);
			if (hash !== "") {
				//hash = hash.replace("#", "").replace("%20", " ");
				console.log("Hash: " + hash);
				setCategory(hash);
				window.scrollTo({ behavior: 'smooth', top: (scrollRef.current.offsetTop - 200) })
			}
		}, [location]
	);

	useEffect(() => {
		async function fetchData() {
			try {
				const result = await axios.get(
					"https://d2zzqzqmnbfbm9.cloudfront.net/documents"
				);

				if (!result.data.errors) {
					//console.log("Resource Data: " + JSON.stringify(result.data.data));
					result.data.data.map(doc => {
						if (doc.id === 7527) {
							doc.filename__v = null;
							doc.external_url__c = "/interactive-infographic";
						} else if (doc.id === 13663) {
							doc.filename__v = null;
							doc.external_url__c = "/auryxia-vbc-infographic";
						}

						return doc;
					});

					var incomingData = result.data.data.filter(
						doc =>
							doc.resource_category__c &&
							doc.resource_category__c[0] === category
					);

					const topics = [];
					const mediaTypes = [];
					const publishers = [];
					const years = [];
					const congresses = [];
					const publicationTypes = [];
					const resourceTypes = [];
					const organizations = [];

					setData(incomingData);
					incomingData.forEach(doc => {
						// FIND TOPICS ON DOCUMENTS FOR CURRENTLY SELECTED RESOURCE CATEGORY
						if (doc.topic__c) {
							doc.topic__c.forEach(topic => {
								if (!topics.some(t => t.value === topic)) {
									topics.push({ key: topic, value: topic });
								}
							});
						}

						// FIND MEDIA TYPES ON DOCUMENTS FOR CURRENTLY SELECTED RESOURCE CATEGORY - array based
						if (
							doc.media_medical__c &&
							!mediaTypes.some(mt => mt.value === doc.media_medical__c[0])
						) {
							mediaTypes.push({
								key: doc.media_medical__c[0],
								value: doc.media_medical__c[0],
							});
						}

						// FIND YEARS ON DOCUMENTS FOR CURRENTLY SELECTED RESOURCE CATEGORY
						if (doc.year__c && !years.some(p => p.value === doc.year__c)) {
							years.push({ key: doc.year__c, value: doc.year__c });
						}

						// FIND PUBLISHERS ON DOCUMENTS FOR CURRENTLY SELECTED RESOURCE CATEGORY
						if (
							doc.publisher__c &&
							!publishers.some(p => p.value === doc.publisher__c)
						) {
							publishers.push({
								key: doc.publisher__c,
								value: doc.publisher__c,
							});
						}

						// FIND CONGRESSES ON DOCUMENTS FOR CURRENTLY SELECTED RESOURCE CATEGORY
						if (
							doc.congress__c &&
							!congresses.some(c => c.value === doc.congress__c)
						) {
							congresses.push({ key: doc.congress__c, value: doc.congress__c });
						}

						// FIND MEDIA TYPES ON DOCUMENTS FOR CURRENTLY SELECTED RESOURCE CATEGORY - array based
						if (
							doc.publication_type__c &&
							!publicationTypes.some(
								pt => pt.value === doc.publication_type__c[0]
							)
						) {
							publicationTypes.push({
								key: doc.publication_type__c[0],
								value: doc.publication_type__c[0],
							});
						}

						// FIND RESOURCE TYPES ON DOCUMENTS FOR CURRENTLY SELECTED RESOURCE CATEGORY - array based
						if (
							doc.resource_type__c &&
							!resourceTypes.some(
								pt => pt.value === doc.resource_type__c[0]
							)
						) {
							resourceTypes.push({
								key: doc.resource_type__c[0],
								value: doc.resource_type__c[0],
							});
						}

						// FIND ORGANIZATION ON DOCUMENTS FOR CURRENTLY SELECTED RESOURCE CATEGORY - array based
						if (
							doc.organization__c &&
							!organizations.some(c => c.value === doc.organization__c)
						) {
							organizations.push({ key: doc.organization__c, value: doc.organization__c });
						}
					});
					// topics
					setCurrentTopics([
						{ key: "0", value: "Select a Topic" },
						...sortFilterAlpha(topics),
					]);
					setSelectedTopic("0");
					// media types
					setCurrentMedia([
						{ key: "0", value: "Select Media" },
						...sortFilterAlpha(mediaTypes),
					]);
					setSelectedMedia("0");
					// years
					setCurrentYears([
						{ key: "0", value: "Select a Year" },
						...sortFilterAlpha(years).reverse(),
					]);
					setSelectedYear("0");
					// resource types
					setCurrentResourceTypes([
						{ key: "0", value: "Select a Resource Type" },
						...sortFilterAlpha(resourceTypes),
					]);
					console.log("Resource Types: " + JSON.stringify(currentResourceTypes));
					setSelectedResourceType("0");
					// organization
					setCurrentOrganization([
						{ key: "0", value: "Select an Organization" },
						...sortFilterAlpha(organizations),
					]);
					console.log("Organization: " + JSON.stringify(currentOrganization));
					setSelectedOrganization("0");
					// publishers
					setCurrentPublishers([
						{ key: "0", value: "Select a Publisher" },
						...sortFilterAlpha(publishers),
					]);
					setSelectedPublisher("0");
					// congresses
					setCurrentCongresses([
						{ key: "0", value: "Select a Congress" },
						...sortFilterAlpha(congresses),
					]);
					setSelectedCongress("0");
					// publication types
					setCurrentPublicationTypes([
						{ key: "0", value: "Select a Publication Type" },
						...sortFilterAlpha(publicationTypes),
					]);
					setSelectedPublicationType("0");
					setSearchInput("");
				}
			} catch (e) {
				// console.log(e);
			}
		}

		fetchData();
	}, [category]);

	const canShowDocuments = category => {
		if (
			category === "Disease Education" ||
			category === "Professional Resources"
		) {
			return true;
		}
		if (
			category === "Congress Activity" &&
			(selectedYear !== "0" ||
				selectedCongress !== "0" ||
				selectedPublicationType !== "0" ||
				selectedTopic !== "0")
		) {
			return true;
		}
		if (
			category === "Publication" &&
			(selectedYear !== "0" ||
				selectedPublisher !== "0" ||
				selectedTopic !== "0")
		) {
			return true;
		}
		if (category === "Medical Information" && searchInput.trim().length > 2) {
			return true;
		}
	};

	const DownloadDocument = doc => {
		if (doc.external_url__c) {
			try {
				if (doc.external_url__c.startsWith("/")) {
					window.open(doc.external_url__c, "_blank");
				} else {
					showModal(doc.external_url__c);
				}
			} catch {
				// no op
			}
		} else if (
			doc.media_medical__c &&
			doc.media_medical__c[0].toLowerCase() === "game"
		) {
			setOpenGameModal(true);
		} else if (
			(doc.media_medical__c &&
				doc.media_medical__c[0].toLowerCase() === "video") ||
			doc.id === 5690
		) {
			setCurrentDocument(doc);
			setVideoPath(
				"https://akebia-file-sync.s3.amazonaws.com/" + doc.filename__v
			);
			setOpenVideoModal(true);
		} else {
			window.open(
				"https://d2zzqzqmnbfbm9.cloudfront.net/download/" + doc.id,
				"_blank"
			);
		}
	};

	const FilterSelectionChange = (filterName, value) => {
		switch (filterName) {
			case "Category":
				if (category !== value) {
					PushAnalytics("Category", value);
				}
				setCategory(value);
				break;
			case "Year":
				if (selectedYear !== value) {
					PushAnalytics("Year", value);
				}
				setSelectedYear(value);
				break;
			case "Congress":
				if (selectedCongress !== value) {
					PushAnalytics("Congress", value);
				}
				setSelectedCongress(value);
				break;
			case "Topic":
				if (selectedTopic !== value) {
					PushAnalytics("Topic", value);
				}
				setSelectedTopic(value);
				break;
			case "Media":
				if (selectedMedia !== value) {
					PushAnalytics("Media", value);
				}
				setSelectedMedia(value);
				break;
			case "Publication Type":
				if (selectedPublicationType !== value) {
					PushAnalytics("Publication Type", value);
				}
				setSelectedPublicationType(value);
				break;
			case "Publisher":
				if (selectedPublisher !== value) {
					PushAnalytics("Publisher", value);
				}
				setSelectedPublisher(value);
				break;
			case "Resource Type":
				if (selectedResourceType !== value) {
					PushAnalytics("Resource Type", value);
				}
				setSelectedResourceType(value);
				break;
			case "Organization":
				if (selectedOrganization !== value) {
					PushAnalytics("Organization", value);
				}
				setSelectedOrganization(value);
				break;
			default:
				break;
		}
	};

	const SearchSelectionChange = value => {
		PushAnalytics("Search", value);
	};

	const PushAnalytics = (filterName, filterSelectedValue) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: filterName === "Search" ? "searchTerm" : "dropdownSelection",
			eventAction: filterName,
			eventLabel: filterSelectedValue,
		});
	};

	return (
		<Layout contact>
			<SEO title="Resources" />
			<Hero background={backdrop}>
				<div className="col-sm-7">
					<h1 className="hero-heading">Resources</h1>
					<p className="hero-copy">
						Explore disease education, congress presentations, manuscripts, and
						more.
					</p>
				</div>
			</Hero>
			{/* Start Breadcrumbs */}
			<div className="section section-noPad">
				<div className="container breadcrumbs">
					<div className="row">
						<div className="col">
							<div className="crumb-container">
								<Link
									to="/"
								>
									Home
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/resources"
									className="active"
								>
									Resources
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrumbs */}
			<div id="disease-education" className="hash-link invisible-hash-link"></div>
			<div id="congress-activity" className="hash-link invisible-hash-link"></div>
			<div id="publication" className="hash-link invisible-hash-link"></div>
			<div id="professional-resources" className="hash-link invisible-hash-link"></div>
			<div id="medical-information" className="hash-link invisible-hash-link"></div>
			<div ref={scrollRef} className="section">
				<div className="container">
					<div className="row">
						<div className="col">
							<p className="no-margin">
								<strong>
									Select a category to explore disease education, congress
									presentations, manuscripts, and more.
								</strong>
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-4">
							<Filter
								name="category-select"
								items={categories}
								selectedItem={category}
								callbackOnChange={value =>
									FilterSelectionChange("Category", value)
								}
								canClose={false}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							{category === "Disease Education" && (
								<p>
									Access videos, infographics, and other media to learn about
									Akebia's areas of research and development.
								</p>
							)}
							{category === "Congress Activity" && (
								<p>
									View Akebia's posters and oral presentations shared during
									scientific congresses. By filtering, you are confirming that
									your query is unsolicited.
								</p>
							)}
							{category === "Publication" && (
								<p>
									Access Akebia’s research manuscripts published in
									peer-reviewed scientific journals. By filtering you are
									confirming that your query is unsolicited.
								</p>
							)}
							{category === "Professional Resources" && (
								<p>
									Access up-to-date informational resources that can assist in
									clinical decision making.
								</p>
							)}
							{category === "Medical Information" && (
								<p>
									Access medical information about Akebia’s approved products,
									investigational drugs, and disease education. By filtering,
									you are confirming that your query is unsolicited.
								</p>
							)}
							{(category === "Medical Information") && (
								<input
									id="search-input"
									name="search-input"
									type="text"
									style={{ width: "100%" }}
									placeholder="Enter keyword(s) or a topic of interest"
									value={searchInput}
									onChange={e => setSearchInput(e.target.value)}
									onBlur={e => SearchSelectionChange(e.target.value)}
								/>
							)}
						</div>
					</div>
					<div className="row">
						{(category === "Congress Activity" ||
							category === "Publication") && (
								<div className="col-sm-3 col-filter">
									<Filter
										name="year-select"
										items={currentYears}
										selectedItem={selectedYear}
										callbackOnChange={value =>
											FilterSelectionChange("Year", value)
										}
										canClose={true}
									/>
								</div>
							)}
						{category === "Congress Activity" && (
							<div className="col-sm-3 col-filter">
								<Filter
									name="congress-select"
									items={currentCongresses}
									selectedItem={selectedCongress}
									callbackOnChange={value =>
										FilterSelectionChange("Congress", value)
									}
									canClose={true}
								/>
							</div>
						)}
						{category === "Publication" && (
							<div className="col-sm-3 col-filter">
								<Filter
									name="publisher-select"
									items={currentPublishers}
									selectedItem={selectedPublisher}
									callbackOnChange={value =>
										FilterSelectionChange("Publisher", value)
									}
									canClose={true}
								/>
							</div>
						)}
						{(category === "Professional Resources") && (
							<div className="col-sm-2 col-filter">
								<Filter
									name="year-select"
									items={currentYears}
									selectedItem={selectedYear}
									callbackOnChange={value =>
										FilterSelectionChange("Year", value)
									}
									canClose={true}
								/>
							</div>
						)}
						{category !== "Medical Information" && (
							<div className="col-sm-3 col-filter">
								<Filter
									name="topic-select"
									items={currentTopics}
									selectedItem={selectedTopic}
									callbackOnChange={value =>
										FilterSelectionChange("Topic", value)
									}
									canClose={true}
								/>
							</div>
						)}
						{category === "Congress Activity" && (
							<div className="col-sm-3 col-filter">
								<Filter
									name="publication-select"
									items={currentPublicationTypes}
									selectedItem={selectedPublicationType}
									callbackOnChange={value =>
										FilterSelectionChange("Publication Type", value)
									}
									canClose={true}
								/>
							</div>
						)}
						{category === "Disease Education" && (
							<div className="col-sm-3 col-filter">
								<Filter
									name="media-select"
									items={currentMedia}
									selectedItem={selectedMedia}
									callbackOnChange={value =>
										FilterSelectionChange("Media", value)
									}
									canClose={true}
								/>
							</div>
						)}
						{category === "Professional Resources" && (
							<div className="col-sm-3 col-filter">
								<Filter
									name="organization-select"
									items={currentOrganization}
									selectedItem={selectedOrganization}
									callbackOnChange={value =>
										FilterSelectionChange("Organization", value)
									}
									canClose={true}
								/>
							</div>
						)}
						{category === "Professional Resources" && (
							<div className="col-sm-4 col-filter">
								<Filter
									name="resource-type-select"
									items={currentResourceTypes}
									selectedItem={selectedResourceType}
									callbackOnChange={value =>
										FilterSelectionChange("Resource Type", value)
									}
									canClose={true}
								/>
							</div>
						)}
					</div>
					<div className="row">
						<div className="col">
							<hr className="resources-hr" />
						</div>
					</div>
					<div className="row">
						{canShowDocuments(category) &&
							currentDocuments.map(item => {
								return (
									<ResourceCard
										callback={e => DownloadDocument(item)}
										resource={item}
										category={category}
										key={item.id}
									/>
								);
							})}
						{!canShowDocuments(category) && (
							<div className="col">
								<p>
									<strong>Use the filters above to display results.</strong>
								</p>
							</div>
						)}
						{canShowDocuments(category) && currentDocuments.length === 0 && (
							<div className="col">
								<p>
									<strong>
										No results meet the selected criteria. Please change the
										filters or search for different keywords.
									</strong>
								</p>
							</div>
						)}
					</div>
					<div className="row">
						<div className="col">
							<p><strong>This information is provided for your educational benefit only and may contain information not approved by the FDA.</strong></p>
						</div>
					</div>
				</div>
			</div>
			{currentDocument && currentDocument.id !== 5750 && (
				<ModalSingleVideo
					isOpen={openVideoModal}
					onRequestClose={() => {
						setOpenVideoModal(false);
					}}
					playlist={[
						{
							video: videoPath,
							title: currentDocument.title__v,
							description: currentDocument.medical_description__c,
							isWide: currentDocument.id === 5690 ? true : false,
						},
					]}
					identifier={
						currentDocument.document_number__v +
						"_" +
						currentDocument.filename__v
					}
				/>
			)}

			{currentDocument && currentDocument.id === 5750 && (
				<ModalExemptSeries
					isOpen={openVideoModal}
					onRequestClose={() => {
						setOpenVideoModal(false);
					}}
				/>
			)}
			<ModalGame
				isOpen={openGameModal}
				onRequestClose={() => {
					setOpenGameModal(false);
				}}
			/>
		</Layout>
	);
};

export default Resources;
